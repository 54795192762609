<template>
    <div class="matomo-setup">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
					<div class="row">
						<div class="col-lg-8">
							<card class="text-center">
								<steps :step="step" />

								<!-- Connect -->
								<template v-if="step === 1">
									<h3>{{ $t('integrations.matomo.step1.title') }}</h3>

									<br>

									<div class="row">
										<div class="col-md-10 col-md-push-1">
											<p class="instructions">

											</p>

											<p class="instructions">
												{{ $t('integrations.matomo.step1.description') }}
											</p>

											<div class="alert alert-danger" v-if="error">
												<strong>{{ $t('integrations.matomo.step1.error.title') }}</strong>
												{{ $t('integrations.matomo.step1.error.description') }}
											</div>

											<div class="well text-center">
												<div class="mb-4">
													<strong class="api-key-label">{{ $t('integrations.matomo.step1.form.url.title') }}</strong>

													<input v-model="clientId"
														   class="form-control"
														   :placeholder="$t('integrations.matomo.step1.form.url.placeholder')"
													/>
												</div>

												<div>
													<strong class="api-key-label">Din API-nøgle</strong>

													<input v-model="clientSecret"
														   class="form-control"
														   :placeholder="$t('integrations.matomo.step1.form.api.placeholder')"
													/>
												</div>
											</div>

											<p class="instructions">
												{{ $t('integrations.matomo.step1.outro') }}
											</p>
										</div>
									</div>

									<hr>

									<button type="button"
											@click="connect"
											:disabled="! hasCredentials || connecting"
											class="btn btn-primary btn-fill btn-lg next pull-right"
											:class="{'disabled': ! hasCredentials}">
										<i v-if="connecting"
										   class="fa fa-spinner fa-spin fa-fw me-2"
										/>
										{{ $t('integrations.matomo.step1.button') }}
									</button>

									<div class="clearfix"></div>
								</template>

								<!-- Integrate -->
								<template v-if="step === 2">
									<h3>{{ $t('integrations.matomo.step2.title') }}</h3>

									<br>

									<div class="row">
										<div class="col-md-10 col-md-push-1">
											<p class="instructions">
												{{ $t('integrations.matomo.step2.description') }}
											</p>

											<div class="alert alert-danger" v-if="error">
												<strong>{{ $t('integrations.matomo.step2.error.title') }}</strong>
												{{ $t('integrations.matomo.step2.error.description') }}
											</div>

											<div class="properties">
												<div v-for="key in Object.keys(properties)"
													 :key="`property-${key}`"
													 @click="property = key"
													 :class="{ checked: property === key }"
													 class="property">
													<span>{{ properties[key] }}</span>
													<div class="box">
														<i class="fa fa-check fa-fw" />
													</div>
												</div>
											</div>

											<p class="instructions">
												{{ $t('integrations.matomo.step2.outro') }}
											</p>
										</div>
									</div>

									<hr>

									<button type="button"
											@click="integrate"
											:disabled="property === null || integrating"
											class="btn btn-primary btn-fill btn-lg next pull-right"
											:class="{'disabled': property === null || integrating}">
										<i v-if="integrating"
										   class="fa fa-spinner fa-spin fa-fw me-2"
										/>
										{{ $t('integrations.matomo.step2.button') }}
									</button>

									<div class="clearfix"></div>
								</template>

								<template v-if="step === 3">
									<h3>{{ $t('integrations.matomo.step3.title') }}</h3>

									<br>

									<div class="row">
										<div class="col-md-10 col-md-push-1">
											<div class="text-center connecting">
												<div class="from">
													<img src="/images/spotonlive-logo.svg" class="logo">
												</div>

												<div class="arrow">
													<span class="pe-7s-angle-left"></span>
												</div>

												<div>
													<img src="/images/matomo.svg" />
												</div>
											</div>
										</div>
									</div>

									<hr>

									<router-link :to="{name: 'frontend.settings.integrations'}" class="btn btn-lg btn-primary btn-fill pull-right">
										{{ $t('integrations.matomo.step3.button') }}
									</router-link>

									<div class="clearfix"></div>
								</template>
							</card>
						</div>

						<div class="col-lg-4">
							<need-help />
						</div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.matomo-setup {
	.instructions {
		font-size: 16px;
	}

	.next {
		color: #fff !important;
		border-color: #fff !important;
	}

	.properties {
		display: flex;
		flex-direction: column;
		margin: 20px 0;

		> div.property {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 6px;
			border-bottom: 1px solid #eee;

			&:last-child {
				border-bottom: 0;
			}

			&:hover {
				cursor: pointer;
				background-color: #f5f5f5;
			}

			span {
				font-size: 16px;
				font-weight: 500;
			}

			> div.box {
				color: transparent;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				width: 30px;
				height: 30px;
				border-radius: 8px;
				border: 2px solid #cbcbcb;
			}

			&.checked {
				> div.box {
					color: #fff;
					border-color: #000;
					background-color: #000;
				}
			}
		}
	}

	.connecting {
		display: table;
		margin: 40px auto;

		.logo {
			width: 240px;
		}

		> div {
			width: 200px;
			display: table-cell;
			vertical-align: middle;

			&.arrow {
				width: 200px;
				text-align: center;
				font-size: 50pt;

				span {
					vertical-align: middle;
				}
			}

			img {
				display: inline-block;
				max-width: 200px;
				max-height: 60px;
			}
		}
	}
}
</style>

<script>
import Card from '@/app/shared/components/Card'
import NeedHelp from "@/app/integrations/components/NeedHelp";
import Steps from "@/app/integrations/components/matomo/Steps";

import MatomoService from '@/services/integrations/matomo/MatomoService'
import {mapActions, mapGetters} from "vuex";

export default {
	data: () => ({
		step: 1,

		service: null,

		clientId: '',
		clientSecret: '',
		connecting: false,

		properties: {},
		property: null,
		integrating: false,

		error: false,
	}),

	computed: {
		hasCredentials() {
			return String(this.clientId || '').length > 5 &&
				String(this.clientSecret || '').length > 5
		},

		...mapGetters('customer', {
			customer: 'getCustomer'
		}),
	},

	methods: {
		...mapActions('identity', {
			reloadIdentity: 'loadIdentity'
		}),

		connect() {
			if (! this.hasCredentials) {
				return
			}

			this.connecting = true
			this.error = false

			MatomoService.connect({
				client_id: this.clientId,
				client_secret: this.clientSecret
			}, ({ data }) => {
				this.connecting = false
				this.properties = data.properties || {}
				this.step++
			}, () => {
				this.connecting = false
				this.error = true
			})
		},

		integrate() {
			if (this.property === null || this.integrating) {
				return
			}

			this.error = false

			const data = {
				data: this.property,
				connectedAccount: this.properties[this.property]
			}

			this.integrating = true

			MatomoService.integrate(data, (response) => {
				this.integrating = false
				this.step++
				this.reloadIdentity()
			}, () => {
				this.integrating = false
				this.error = true
			})
		}
	},

	components: {
		Steps,
		NeedHelp,
		Card,
	}
}
</script>
