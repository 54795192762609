const BaseService = require('@/services/BaseService')

const namespace = 'orders'

const integrate = (integration, options, cb, errorCallback) => {
  BaseService.post(namespace + '/integrate/' + integration, options, cb, errorCallback);
}

export {
  integrate
}
