<template>
    <div class="calltracking-setup-step1">
        <div class="row">
            <div class="col-lg-8">
                <card class="text-center">
                    <h3>Indstillinger for opkaldstracking</h3>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <p class="instructions">
                                Tilføj telefonnumre, som er oplyst på din virksomheds hjemmeside.
                            </p>

                        </div>
                    </div>

                    <br>

                    <phone-numbers :customer="customer" :customerId="customer.id" />
                </card>
            </div>

            <div class="col-lg-4">
                <card class="text-center">
                    <h4>Gentag opsætning</h4>

                    <p class="instructions">
                        Sæt opkaldstracking op på ny. Vælg opsætningsmetode nedenfor.
                    </p>

                    <div class="services">
                        <!-- WordPress -->
                        <div class="col-xs-6 col-sm-4 col-sm-push-2 col-lg-6 col-lg-push-0">
                            <router-link :to="{name: 'frontend.settings.integrations.calltracking.setup.wordpressInstallPlugin'}">
                                <div class="service">
                                    <img src="/images/calltracking/services/wordpress.svg"><br />
                                    <span>WordPress</span>
                                </div>
                            </router-link>
                        </div>

                        <!-- HTML -->
                        <div class="col-xs-6 col-sm-4 col-sm-push-2 col-lg-6 col-lg-push-0">
                            <router-link :to="{name: 'frontend.settings.integrations.calltracking.setup.copyHtml'}">
                                <div class="service">
                                    <img src="/images/calltracking/services/html.svg"><br />
                                    <span>HTML</span>
                                </div>
                            </router-link>
                        </div>

                        <div class="clearfix"></div>
                    </div>
                </card>

                <need-help></need-help>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .calltracking-setup-step1 {
        .instructions {
            font-size: 16px;
        }

        .services {
            .service {
                cursor: pointer;
                padding: 15px 20px;
                text-align: center;
                border-radius: 8px;

                span {
                    color: initial;
                }

                img {
                    max-width: 80%;
                    margin-bottom: 10px;
                }

                &:hover {
                    background-color: #f5f5f5;
                }
            }
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import PhoneNumbers from '@/app/integrations/components/calltracking/PhoneNumbers'
    import NeedHelp from '@/app/integrations/components/NeedHelp'

    import {mapGetters} from 'vuex'

    export default {
      computed: {
        ...mapGetters('identity', {
          identity: 'getIdentity'
        }),
        ...mapGetters('customer', {
          customer: 'getCustomer'
        })
      },

      methods: {
        goBack() {
          history.back()
        }
      },

      components: {
        Card,
        PhoneNumbers,
        NeedHelp,
      }
    }
</script>
