const BaseService = require('@/services/BaseService')

const namespace = 'reviews'

const find = (id, options, cb, errorCb) => {
  BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
  BaseService.get(namespace, options, cb, errorCb)
}

const sum = (field, options, cb, errorCb) => {
  BaseService.get(namespace + '/sum/' + field, options, cb, errorCb)
}

const integrate = (body, cb, errorCb) => {
  BaseService.post(namespace + '/integration', body, cb, errorCb)
}

export {
  find,
  findAll,
  sum,
  integrate
}
