<template>
    <div class="dinero-success">
        <div class="row">
            <div class="col-lg-8">
                <card class="text-center">
                    <steps :step="4"></steps>

                    <h3>OP'N etablerer samarbejde med dinero</h3>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <div class="text-center connecting">
                                <div class="from">
                                    <img src="/images/spotonlive-logo.svg" class="logo">
                                </div>

                                <div class="arrow">
                                    <span class="pe-7s-angle-left"></span>
                                </div>

                                <div>
                                    <img src="/images/economy/dinero_dark.png" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-10 col-md-offset-1">
                                    <p class="text-center instructions" v-html="$t('economy.description', {financialSystem: 'Dinero', email: identity.email})"></p>
                                </div>
                            </div>

                            <template v-if="hasLoaded">
                                <div class="text-center success-button" v-if="!error">
                                    <router-link :to="{name: 'frontend.reporting.economy.onboarding.matches'}" class="btn btn-primary btn-fill btn-lg">
                                        {{$t('economy.link')}} <i class="fa fa-angle-right"></i>
                                    </router-link>
                                </div>
                            </template>
                            <br><br>
                        </div>
                    </div>

                    <hr>

                    <router-link :to="{name: 'frontend.settings.integrations'}" class="btn btn-lg btn-primary btn-fill pull-right">Færdig</router-link>

                    <div class="clearfix"></div>
                </card>
            </div>

            <div class="col-lg-4">
                <need-help></need-help>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .dinero-success {
        .instructions {
            font-size: 16px;
        }

        .connecting {
            display: table;
            margin: 40px auto;

            .logo {
                width: 240px;
            }

            > div {
                width: 200px;
                display: table-cell;
                vertical-align: middle;

                &.arrow {
                    width: 200px;
                    text-align: center;
                    font-size: 50pt;

                    span {
                        vertical-align: middle;
                    }
                }

                img {
                    display: inline-block;
                    max-width: 200px;
                    max-height: 60px;
                }
            }
        }

        .success-button {
            margin-top: 50px;
        }
    }
</style>

<script>
  import Steps from '@/app/integrations/components/dinero/Steps'
  import NeedHelp from '@/app/integrations/components/NeedHelp'

  import {mapGetters} from 'vuex'

  export default {
    computed: mapGetters('identity', {
      identity: 'getIdentity'
    }),

    components: {
      Steps,
      NeedHelp,
    }
  }
</script>
