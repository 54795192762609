<template>
    <div class="dinero-setup-choose-configuration">
        <div class="row">
            <div class="col-lg-8">
                <card class="text-center">
                    <steps :step="1"></steps>

                    <h3>Integrér med Dinero</h3>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <p class="instructions">

                            </p>

                            <p class="instructions">
                                For at skabe en sikker forbindelse mellem Dinero og OP'N skal du
                                bruge en såkaldt API-nøgle til dette formål. API-nøglen er heldigvis nem og hurtig at finde. Følg denne guide for at komme igang.

                            </p>

                            <p class="instructions">
                                OBS: Dinero vil kræve, at du har et Dinero Pro-abonnement for at skabe en forbindelse. Hvis du ikke har et Dinero Pro-abonnement, kan du opgradere nu og derefter fortsætte guiden.
                            </p>

                            <p class="instructions">
                                Tryk ‘Næste’ for at komme i gang
                            </p>
                        </div>
                    </div>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <div class="dinero-check-container text-left">
                                <input type="checkbox" name="hasDineroPro" v-model="hasDineroPro">
                                <strong>Jeg har Dinero Pro</strong>
                                <img class="logo pull-right" src="/images/economy/dinero_dark.png">
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>

                    <br>
                    <br>

                    <hr>

                    <router-link :to="{name: 'frontend.settings.integrations.dinero.setup.inputApiKey'}"
                                 class="btn btn-primary btn-fill btn-lg pull-right"
                                 :class="{'disabled': !hasDineroPro}">
                        Næste
                    </router-link>

                    <div class="clearfix"></div>
                </card>
            </div>

            <div class="col-lg-4">
                <need-help></need-help>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .dinero-setup-choose-configuration {
        .instructions {
            font-size: 16px;
        }

        .dinero-check-container {
            background: #f9f9f9;
            border: 1px solid #dedede;
            border-radius: 4px;
            line-height: 40px;
            padding: 6px 22px;

            input {
                margin-right: 15px;
            }

            strong {
                font-size: 16px;
            }

            img {
                margin-top: 6px;
            }
        }

        .logo {
            height: 28px;
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import Steps from '@/app/integrations/components/dinero/Steps'
    import NeedHelp from '@/app/integrations/components/NeedHelp'

    export default {
        data() {
            return {
                hasDineroPro: false,
            }
        },

        components: {
            Card,
            Steps,
            NeedHelp,
        }
    }
</script>
