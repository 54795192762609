<template>
	<div class="klaviyo-steps">
		<div class="steps text-left">
			<div class="step" :class="{'active' : step === 1}">
				<span class="number">1</span>
				{{ $t('integrations.klaviyo.step1.short') }}
			</div>

			<div class="step" :class="{'active' : step === 2}">
				<span class="number">2</span>
				{{ $t('integrations.klaviyo.step2.short') }}
			</div>

			<div class="clearfix"></div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.klaviyo-steps {
	.steps {
		margin: -30px -30px 45px -30px;
		padding: 1px 0 10px;

		.step {
			border-bottom: 1px solid #dedede;
			float: left;
			width: 25%;
			line-height: 65px;
			color: #999;
			padding-left: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			.number {
				border: 1px solid #dedede;
				border-radius: 999px;
				width: 37px;
				line-height: 35px;
				display: inline-block;
				margin-right: 8px;
				text-align: center;
			}

			&.active {
				color: initial;
				border-bottom: 1px solid #4096ee;
			}
		}
	}
}
</style>

<script>
export default {
	props: {
		step: {
			type: Number,
			default: 1
		}
	},

	mounted() {
		this.scrollToTop();
	},

	methods: {
		scrollToTop() {
			this.$el.scrollIntoView(true)
		}
	}
}
</script>
