<template>
    <div class="form-setup-copy-html">
        <div class="row">
            <div class="col-lg-8">
                <card class="text-center">
                    <steps :step="2"></steps>

                    <h3>Download og installér Wordpress plugin</h3>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <p class="instructions">
                                Find det plugin i Wordpress, der hedder OP'N og installér. Du finder OP'N som plugin ved at gå til <code>Plugins  > Tilføj nyt </code> i dit administrationspanel i WordPress. Eller ved at klikke på knappen <code>Installér plugin</code> herunder.
                            </p>

                            <p class="instructions">
                                Klik på <code>Installér nu</code> og derefter <code>Aktiver</code>, når du har fundet SpotOn Live i WordPress. Husk, at vend tilbage hertil for at færdiggøre din opsætning efter installationen.
                            </p>

                            <br>

                            <div>
                                <a class="btn btn-primary btn-fill btn-lg" target="_blank" rel="noopener noreferrer" :href="'http://' + domain + '/wp-admin/plugin-install.php?s=spoton-live&tab=search&type=term'">Installer plugin</a>
                                <a class="btn btn-default btn-lg" data-toggle="modal" data-target="#guideModal">Uddybende guide</a>
                            </div>

                            <br>
                            <br>

                            <div class="row">
                                <div class="col-md-10 col-md-push-1">
                                    <img src="/images/settings/integrations/wordpress/wordpress-plugin.png" class="img-responsive" />
                                </div>
                            </div>

                            <wordpress-guide-modal></wordpress-guide-modal>
                        </div>
                    </div>

                    <br>

                    <hr>

                    <router-link :to="{name: 'frontend.settings.integrations.formtracking.setup.chooseConfiguration'}" class="btn btn-default btn-lg pull-left">Tilbage</router-link>
                    <router-link :to="{name: 'frontend.settings.integrations.formtracking.setup.wordpressApiKey'}" class="btn btn-primary btn-fill btn-lg pull-right">Næste</router-link>

                    <div class="clearfix"></div>
                </card>
            </div>

            <div class="col-lg-4">
                <need-help></need-help>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .form-setup-copy-html {
        .instructions {
            font-size: 16px;
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import CodeSnippet from '@/app/integrations/components/calltracking/CodeSnippet'
    import Steps from '@/app/integrations/components/formtracking/Steps'
    import NeedHelp from '@/app/integrations/components/NeedHelp'
    import WordpressGuideModal from '@/app/integrations/components/WordpressGuideModal.vue'

    import {mapGetters} from 'vuex'

    export default {
      computed: {
        ...mapGetters('identity', {
          identity: 'getIdentity'
        }),
        ...mapGetters('customer', {
          customer: 'getCustomer'
        }),

        domain() {
          if (!this.customer) {
            return null;
          }

          return this.customer.domain;
        }
      },

      components: {
        Card,
        CodeSnippet,
        Steps,
        NeedHelp,
        WordpressGuideModal,
      }
    }
</script>
