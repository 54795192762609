<template>
    <div class="integrations-page">
        <div class="container-fluid">
            <h4>{{$t('integrations.page.recommendedIntegrations')}}</h4>

            <hr>

            <div class="row row-lg-flex"
				 style="flex-wrap: wrap;">
                <div v-if="showGoogleAnalytics"
					 class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-analytics" :compact="true">
                        <integration name="Google analytics"
                                     service="analytics"
                                     styling="portrait"
                                     logo="/images/onboarding/google-analytics.svg"
                                     :headline="'Google Analytics'"
                                     :description="$t('integrations.list.googleAnalytics.description')"
						/>
                    </card>
                </div>

                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-call-tracking" :compact="true">
                        <ribbon text="Business"></ribbon>

                        <integration logo="/images/onboarding/calls.png"
                                     styling="portrait"
                                     :headline="$t('integrations.list.callTracking.headline')"
                                     :description="$t('integrations.list.callTracking.description')">
                            <template v-if="!hasIntegration('Freespee')">
                                <div v-if="! isFinland">
                                    <router-link :to="{name: 'frontend.settings.integrations.calltracking.setup.chooseConfiguration'}"
                                                 class="btn btn-primary btn-fill btn-lg"
                                                 :style="organizationBackgroundColor">
                                        {{$t('integrations.setUp')}}
                                    </router-link>
                                </div>

								<div v-else
									 class="coming-soon">
									Tulossa pian
								</div>
                            </template>

                            <template v-else>
                                <div class="connected connected-lg text-muted">
                                    <span class="fa fa-check fa-fw"></span>
                                    {{$t('integrations.isSetUp')}}
                                </div>

                                <router-link :to="{name: 'frontend.settings.integrations.calltracking.setup.settings'}" class="connected connected-lg">
                                    <i class="fa fa-cog"></i>
                                </router-link>

                                <br>
                                <br>

                                <div>
                                    <remove-integration :headline="$t('integrations.list.callTracking.headline')" name="Freespee"></remove-integration>
                                </div>

                                <template v-if="isIntegrationsPage && connectedAccountForIntegration('Freespee')">
                                    <small class="text-muted">{{$t('onboarding.connectedTo')}} </small>

                                    <span class="g-dynamic-value" style="font-size: 10px;">{{connectedAccountForIntegration('Freespee')}}</span>
                                </template>
                            </template>
                        </integration>
                    </card>
                </div>

                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-form-tracking" :compact="true">
                        <integration logo="/images/onboarding/forms.png"
                                     styling="portrait"
                                     :headline="$t('integrations.list.formTracking.headline')"
                                     :description="$t('integrations.list.formTracking.description')">
                            <template v-if="!(hasIntegration('Formstack') || hasIntegration('WordPress forms'))">
                                <div v-if="! isFinland">
                                    <router-link :to="{name: 'frontend.settings.integrations.formtracking.setup.chooseConfiguration'}"
                                                 class="btn btn-primary btn-fill btn-lg"
                                                 :style="organizationBackgroundColor">
                                        {{$t('integrations.setUp')}}
                                    </router-link>
                                </div>

								<div v-else
									 class="coming-soon">
									Tulossa pian
                                </div>
                            </template>

                            <template v-else>
                                <div class="connected connected-lg text-muted">
                                    <span class="fa fa-check fa-fw"></span>
                                    {{$t('integrations.isSetUp')}}
                                </div>

                                <router-link :to="{name: 'frontend.settings.integrations.formtracking.setup.chooseConfiguration'}" class="connected connected-lg">
                                    <i class="fa fa-cog"></i>
                                </router-link>

                                <br>
                                <br>

                                <div v-if="!hasIntegration('Formstack')">
                                    <remove-integration :headline="$t('integrations.list.formTracking.headline')" name="WordPress forms"></remove-integration>
                                </div>

                                <template v-if="isIntegrationsPage && connectedAccountForIntegration('Formstack')">
                                    <small class="text-muted">{{$t('onboarding.connectedTo')}} </small>

                                    <span class="g-dynamic-value" style="font-size: 10px;">{{connectedAccountForIntegration('Formstack')}}</span>
                                </template>
                            </template>
                        </integration>
                    </card>
                </div>

				<div v-if="showMatomo"
					 class="col-lg-4 row-lg-flex">
					<card class="text-center scroll-to scroll-to-dinero" :compact="true">
						<integration logo="/images/matomo.svg"
									 styling="portrait"
									 :headline="$t('integrations.list.matomo.headline')"
									 :description="$t('integrations.list.matomo.description')">
							<template v-if="!hasIntegration('Google analytics')">
								<div>
									<router-link :to="{name: 'frontend.settings.integrations.matomo.setup'}"
												 class="btn btn-primary btn-fill btn-lg"
												 :style="organizationBackgroundColor">
										{{$t('integrations.setUp')}}
									</router-link>
								</div>
							</template>

							<template v-else>
								<div class="connected connected-lg text-muted">
									<span class="fa fa-check fa-fw"></span>
									{{$t('integrations.isSetUp')}}
								</div>

								<br>
								<br>

								<div>
									<remove-integration :headline="$t('integrations.list.matomo.headline')"
														name="Google analytics"
									/>
								</div>
							</template>
						</integration>
					</card>
				</div>

                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-google-google-search-console" :compact="true">
                        <integration name="Google Search Console" service="google-search-console" styling="portrait" logo="/images/google/google-search-console.svg" :headline="$t('integrations.list.googleSearchConsole.headline')" :description="$t('integrations.list.googleSearchConsole.description')"></integration>
                    </card>
                </div>

                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-google-mybusiness" :compact="true">
                        <integration name="MailChimp" service="mail-chimp" styling="portrait" logo="/images/mailchimp.svg" :headline="$t('integrations.list.mailChimp.headline')" :description="$t('integrations.list.mailChimp.description')"></integration>
                    </card>
                </div>

                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center" :compact="true">
                        <integration name="Klaviyo"
									 styling="portrait"
									 logo="/images/klaviyo.svg"
									 :headline="$t('integrations.list.klaviyo.headline')"
									 :description="$t('integrations.list.klaviyo.description')"
						>
							<template v-if="! hasIntegration('Klaviyo')">
								<div>
									<router-link :to="{name: 'frontend.settings.integrations.klaviyo.setup'}"
												 class="btn btn-primary btn-fill btn-lg"
												 :style="organizationBackgroundColor">
										{{$t('integrations.setUp')}}
									</router-link>
								</div>
							</template>

							<template v-else>
								<div class="connected connected-lg text-muted">
									<span class="fa fa-check fa-fw"></span>
									{{$t('integrations.isSetUp')}}
								</div>

								<br />
								<br />

								<remove-integration :headline="$t('integrations.list.klaviyo.headline')" name="Klaviyo" />
							</template>
						</integration>
                    </card>
                </div>

				<div class="col-lg-4 row-lg-flex">
                    <card class="text-center" :compact="true">
                        <integration name="WordPress"
									 styling="portrait"
									 logo="/images/wordpress.svg"
									 :headline="$t('integrations.list.wordpress.headline')"
									 :description="$t('integrations.list.wordpress.description')"
						>
							<template v-if="! wordpress || ! wordpress.verifiedAt">
								<div>
									<router-link :to="{name: 'frontend.wordpress'}"
												 class="btn btn-primary btn-fill btn-lg"
												 :style="organizationBackgroundColor">
										{{$t('integrations.setUp')}}
									</router-link>
								</div>
							</template>

							<template v-else>
								<div class="connected connected-lg text-muted">
									<span class="fa fa-check fa-fw"></span>
									{{$t('integrations.isSetUp')}}
								</div>

								<br />
								<br />

								<router-link :to="{ name: 'frontend.wordpress' }"><i class="fa fa-cog fa-fw"></i></router-link>
							</template>
						</integration>
                    </card>
                </div>
            </div>

            <h4>{{$t('integrations.page.reviews')}}</h4>

            <hr>

            <div class="row row-lg-flex">
                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-facebook-pages" :compact="true">
                        <integration name="Facebook pages"
                                     service="facebook-pages"
                                     styling="portrait"
                                     :logos="['/images/onboarding/facebook.svg','/images/onboarding/instagram.svg']"
                                     :headline="$t('social.onboarding.headline')"
                                     :description="$t('social.onboarding.description')"></integration>
                    </card>
                </div>

                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-trustpilot" :compact="true">
                        <integration logo="/images/onboarding/trustpilot.png" styling="portrait" :headline="$t('integrations.list.trustpilot.headline')" :description="$t('integrations.list.trustpilot.description')">
                            <template v-if="!hasIntegration('Trustpilot review')">
                                <div>
                                    <router-link :to="{name: 'frontend.settings.integrations.trustpilot.setup'}"
                                                 href="#"
                                                 class="btn btn-primary btn-fill btn-lg"
                                                :style="organizationBackgroundColor">
                                        {{$t('onboarding.connect')}}
                                    </router-link>
                                </div>
                            </template>
                            <template v-else>
                                <div class="connected connected-lg text-muted">
                                    <span class="fa fa-check fa-fw"></span>
                                    {{$t('onboarding.connected')}}
                                </div>

                                <br>
                                <br>

                                <div>
                                    <remove-integration :headline="$t('integrations.list.trustpilot.headline')" name="Trustpilot review"></remove-integration>
                                </div>

                                <template v-if="isIntegrationsPage && connectedAccountForIntegration('Trustpilot review')">
                                    <small class="text-muted">{{$t('onboarding.connectedTo')}} </small>

                                    <span class="g-dynamic-value" style="font-size: 10px;">{{connectedAccountForIntegration('Trustpilot review')}}</span>
                                </template>
                            </template>
                        </integration>
                    </card>
                </div>

                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-google-mybusiness" :compact="true">
                        <integration :isAccess="true"
                                     serviceNamespace="google-business-profile"
                                     name="Google Business Profile"
                                     service="google-business-profile"
                                     styling="portrait"
                                     logo="/images/onboarding/google-business.svg"
                                     :headline="$t('integrations.list.googleBusinessProfile.headline')"
                                     :description="$t('integrations.list.googleBusinessProfile.description')">
                        </integration>
                    </card>
                </div>
			</div>

			<div v-if="1 === 2" class="row row-lg-flex">
                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-linked-in-social" :compact="true">
						<integration name="LinkedIn Social"
									 service="linked-in-social"
									 styling="portrait"
									 logo="/images/onboarding/linkedin.svg"
									 :headline="$t('integrations.list.linkedInSocial.headline')"
									 :description="$t('integrations.list.linkedInSocial.description')"></integration>
                    </card>
                </div>
            </div>

            <h4>{{$t('integrations.page.advertisement')}}</h4>

            <hr>

            <div class="row row-lg-flex">
                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-google-ads" :compact="true">
                        <integration name="Google Ads"
                                     serviceNamespace="access-google-ppc"
                                     service="google-ads"
                                     styling="portrait"
                                     logo="/images/onboarding/google-adwords.svg"
                                     :headline="$t('integrations.list.googleAds.headline')"
                                     :description="$t('integrations.list.googleAds.description')"
                                     :isAccess="true">
                        </integration>
                    </card>
                </div>

                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-facebook-ads" :compact="true">
                        <integration name="Facebook ads"
                                     service="facebook-ads"
                                     styling="portrait"
                                     logo="/images/onboarding/facebook-ads.svg"
                                     :headline="$t('integrations.list.facebookBusinessManager.headline')"
                                     :description="$t('integrations.list.facebookBusinessManager.description')"></integration>
                    </card>
                </div>

                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-linkedin" :compact="true">
                        <integration name="LinkedIn"
                                     service="linked-in"
                                     styling="portrait"
                                     logo="/images/onboarding/linkedin.svg"
                                     :headline="$t('integrations.list.linkedIn.headline')"
                                     :description="$t('integrations.list.linkedIn.description')"></integration>
                    </card>
                </div>
            </div>

            <h4>{{$t('integrations.page.economy')}}</h4>

            <hr>

            <div class="row row-lg-flex">
                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-economic" :compact="true">
                        <integration logo="/images/onboarding/e-conomic.png"
                                     styling="portrait"
                                     :headline="$t('integrations.list.economic.headline')"
                                     :description="$t('integrations.list.economic.description')">
                            <template v-if="!hasIntegration('Order: E-conomic')">
                                <div>
                                    <a @click="connectEconomic()" class="btn btn-primary btn-fill btn-lg" :style="organizationBackgroundColor">
                                        {{$t('integrations.connect')}}
                                    </a>
                                </div>
                            </template>

                            <template v-else>
                                <div class="connected connected-lg text-muted">
                                    <span class="fa fa-check fa-fw"></span>
                                    {{$t('integrations.connected')}}
                                </div>

                                <br>
                                <br>

                                <div>
                                    <remove-integration :headline="$t('integrations.list.economic.headline')" name="Order: E-conomic"></remove-integration>
                                </div>
                            </template>
                        </integration>
                    </card>
                </div>

                <div class="col-lg-4 row-lg-flex">
                    <card class="text-center scroll-to scroll-to-dinero" :compact="true">
                        <integration logo="/images/onboarding/dinero.png" styling="portrait" :headline="$t('integrations.list.dinero.headline')" :description="$t('integrations.list.dinero.description')">
                            <template v-if="!hasIntegration('Order: Dinero')">
                                <div>
                                    <router-link :to="{name: 'frontend.settings.integrations.dinero.setup.startPage'}"
                                                 class="btn btn-primary btn-fill btn-lg"
                                                 :style="organizationBackgroundColor">
                                        {{$t('integrations.setUp')}}
                                    </router-link>
                                </div>
                            </template>

                            <template v-else>
                                <div class="connected connected-lg text-muted">
                                    <span class="fa fa-check fa-fw"></span>
                                    {{$t('integrations.isSetUp')}}
                                </div>

                                <br>
                                <br>

                                <div>
                                    <remove-integration :headline="$t('integrations.list.dinero.headline')" name="Order: Dinero"></remove-integration>
                                </div>
                            </template>
                        </integration>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .integrations-page {
        .fa-cog {
            color: #4e4e4e;
        }
    }

	.coming-soon {
		color: #000;
		font-style: italic;
		font-size: 16px;
		font-weight: 400;
	}

    .scroll-to {
        transition: all 0.5s ease-in-out;

        &.highlight {
            transform: scale(1.07);
            box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
        }
    }
</style>

<script>
import Card from '@/app/shared/components/Card'
import Integration from '@/app/onboarding/components/integrations/Integration'
import Ribbon from '@/app/layout/components/Ribbon'

import RemoveIntegration from '@/app/onboarding/components/integrations/RemoveIntegration'

import hasIntegration from '@/mixins/integrations/hasIntegration'
import connectEconomic from '@/mixins/integrations/connectEconomic'

import { mapGetters } from 'vuex'

const VueScrollTo = require('vue-scrollto');

import WordPressService from '@/services/wordpress/WordPressService'

export default {
    mixins: [
        hasIntegration,
        connectEconomic,
    ],

	data: () => ({
		wordpress: null
	}),

    computed: {
      ...mapGetters('ghost', ['isGhostIdentity']),

        organizationColor() {
            return {color:$org('colors.standard.integrations.connect')}
        },

		isFinland() {
			return !! this.customer && !! this.customer.country && this.customer.country.code === 'FI'
		},

        organizationBackgroundColor() {
            return {backgroundColor:$org('colors.standard.integrations.connect')}
        },

        scrollToClass() {

            if (!this.$route) {
                return
            }

            if (!this.$route.query) {
                return
            }

            if (!this.$route.query.scrollToClass) {
                return
            }

            return this.$route.query.scrollToClass
        },

        isIntegrationsPage() {
            return this.$route.name === 'frontend.settings.integrations'
        },
    },

    mounted() {
        this.scrollToElement();
		this.loadWordPress()
    },

    methods: {
		loadWordPress() {
			WordPressService.get(
				{},
				({ data }) => {
					this.wordpress = data
				},
				(response) => {
				}
			)
		},

        connectedAccountForIntegration(name) {
            const integration = this.getIntegration(name)

            if (!integration || !integration.connectedAccount) {
                return null
            }

            return integration.connectedAccount
        },

        scrollToElement() {
            const element = this.$el.querySelector('.' + this.scrollToClass)

            setTimeout(() => {
                VueScrollTo.scrollTo(element, 400, {
                    offset: -100,
                    easing: 'ease-in-out',
                    onDone: function() {
                        element.className += ' highlight';
                    },
                })
            }, 400)
        }
    },

    components: {
        Card,
        Integration,
        Ribbon,
        RemoveIntegration,
    }
}
</script>
