<template>
    <div class="form-setup-layout">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .form-setup-layout {

    }
</style>

<script>
    import Card from '@/app/shared/components/Card'

    export default {
      components: {
        Card,
      }
    }
</script>
