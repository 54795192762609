<template>
    <div class="calltracking-setup-copy-html">
        <div class="row">
            <div class="col-lg-8">
                <card class="text-center">
                    <steps :step="2"></steps>

                    <h3>Indsæt HTML-kode</h3>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <p class="instructions">
                                Indsæt nedenstående HTML-kode på din hjemmeside. Koden skal være indsat på alle dine
                                undersider og være placeret efter dit <code>&lt;body&gt;</code>-tag.
                            </p>
                        </div>
                    </div>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <code-snippet :customer="customer"></code-snippet>
                        </div>
                    </div>

                    <hr>

                    <router-link :to="{name: 'frontend.settings.integrations.calltracking.setup.chooseConfiguration'}" class="btn btn-default btn-lg pull-left">Tilbage</router-link>
                    <router-link :to="{name: 'frontend.settings.integrations.calltracking.setup.addPhoneNumbers'}" class="btn btn-primary btn-fill btn-lg pull-right">Næste</router-link>

                    <div class="clearfix"></div>
                </card>
            </div>

            <div class="col-lg-4">
                <need-help></need-help>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .calltracking-setup-copy-html {
        .instructions {
            font-size: 16px;
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import CodeSnippet from '@/app/integrations/components/calltracking/CodeSnippet'
    import Steps from '@/app/integrations/components/calltracking/Steps'
    import NeedHelp from '@/app/integrations/components/NeedHelp'

    import {mapGetters} from 'vuex'

    export default {
      computed: {
        ...mapGetters('identity', {
          identity: 'getIdentity'
        }),
        ...mapGetters('customer', {
          customer: 'getCustomer'
        })
      },

      components: {
        Card,
        CodeSnippet,
        Steps,
        NeedHelp,
      }
    }
</script>
