const BaseService = require('@/services/BaseService')

const namespace = 'reviews/trustpilot'

const search = (query, options, cb, errorCb) => {
  BaseService.get(namespace + '/search/' + query, options, cb, errorCb)
}

export {
  search
}
