<template>
    <div class="calltracking-sales-page container-fluid">
        <div class="row">
            <div class="col-md-5">
                <img src="/images/integrations/cta-messages/mail-and-calls.png" class="img-responsive">
            </div>

            <div class="col-md-7">
                <h3>
                    Få fuld indsigt i alle dine henvendelser med opkaldstracking - en del af OP'N Business-pakken
                </h3>

                <p class="text-muted">
                    Med opkaldstracking kan du få uundværlig indsigt i de telefoniske henvendelser, som din virksomhed modtager. Se i hvilke tidsrum du modtager flest opkald, find ud af hvornår du har mange ubesvarede opkald og lyt igen til dine samtaler, hvis du har glemt en vigtig oplysning.
                </p>

                <br>

                <card>
                    <h4>Med en Business-pakke i OP'N kan du:</h4>

                    <div class="row">
                        <div class="col-md-6">
                            <ul>
                                <li><i class="check  pe-7s-check"></i>Måle udviklingen i henvendelser pr. telefon</li>
                                <li><i class="check  pe-7s-check"></i>Få en dybere indsigt i effekten af din markedsføring</li>
                                <li><i class="check  pe-7s-check"></i>Optage 100 min. samtale pr. måned og lytte igen</li>
                            </ul>
                        </div>

                        <div class="col-md-6">
                            <ul>
                                <li><i class="check  pe-7s-check"></i>Se hvilke tidsrum, du går glip af nye opkald</li>
                                <li><i class="check  pe-7s-check"></i>Se op til 30 af dine søgeplaceringer på Google</li>
                                <li><i class="check  pe-7s-check"></i>Få en daglig opdatering af dine Google-placeringer</li>
                            </ul>
                        </div>
                    </div>

                    <br>

                    <div>
                        <router-link :to="{name: 'frontend.settings.upgrade', params: {plan: 'd10fc466-b511-4ee2-9ffe-289199da3feb'}, query: {callback: 'frontend.settings.integrations.calltracking.setup.chooseConfiguration'}}" class="btn btn-primary btn-fill btn-lg pull-left">
                            Prøv 30 dage gratis
                        </router-link>

                        <div class="price pull-left">
                            <small>kr</small>
                            <span>199</span>
                            <small>/måned</small>
                        </div>

                        <div class="clearfix"></div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .calltracking-sales-page {
        font-size: 16px;

        p {
            font-size: 16px;
        }

        h3 {
            font-size: 26px;
            margin-bottom: 25px;
            margin-top: 75px;
            line-height: 1.5;
        }

        h4 {
            font-size: 20px;
            margin-top: 5px;
        }

        ul {
            list-style: none;

            li {
                position: relative;
                padding-left: 5px;
                line-height: 25px;
                margin-top: 10px;
                margin-bottom: 25px;

                .check {
                    position: absolute;
                    left: -40px;
                    top: 2px;
                    color: #00c69e;
                    font-size: 35px;
                }
            }
        }

        .btn-calls {
            background: #76c049;
        }

        .price {
            margin-left: 15px;
            margin-top: -3px;

            small {
                font-size: 12px;
            }

            span {
                font-size: 34px;
            }
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'

    export default {
        components: {
            Card
        }
    }
</script>
