<template>
    <div class="formtracking-setup-choose-configuration">
        <div class="row">
            <div class="col-lg-8">
                <card class="text-center">
                    <steps :step="1"></steps>

                    <h3>Opsætning af ‘Emailtracking’</h3>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <p class="instructions">
                                Emailtracking tilbydes til brugere, der har en WordPress-hjemmeside og som benytter sig af <a href="http://www.gravityforms.com/" target="_blank" rel="noopener noreferrer">Gravity Forms</a> eller <a href="https://contactform7.com/" target="_blank" rel="noopener noreferrer">ContactForm7</a> til at modtage e-mails. Denne guide vil vise dig, hvordan du nemt og hurtigt kan opsætte emailtracking ved brug af vores OP'N Wordpress-plugin.
                            </p>

                            <p class="instructions">
                                Denne guide vil vise dig, hvordan du nemt og hurtigt kan opsætte emailtracking ved brug af vores OP'N Wordpress-plugin.
                            </p>
                        </div>
                    </div>

                    <br />

                    <div class="services">
                        <!-- WordPress -->
                        <div class="col-md-6 col-md-push-3 col-sm-6 col-sm-push-3">
                                <div class="service">
                                    <img src="/images/calltracking/services/wordpress.svg"><br />
                                </div>
                        </div>
                    </div>

                    <div class="clearfix"></div>

                    <br>

                    <hr>

                    <router-link :to="{name: 'frontend.settings.integrations.formtracking.setup.wordpressInstallPlugin'}" class="btn btn-lg btn-primary btn-fill pull-right">Næste</router-link>

                    <div class="clearfix"></div>
                </card>
            </div>

            <div class="col-lg-4">
                <need-help></need-help>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .formtracking-setup-choose-configuration {
        .instructions {
            font-size: 16px;
        }

        .services {
            .service {
                padding: 15px 20px;
                text-align: center;
                border-radius: 8px;

                span {
                    color: initial;
                }

                img {
                    max-width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import Steps from '@/app/integrations/components/formtracking/Steps'
    import NeedHelp from '@/app/integrations/components/NeedHelp'

    export default {
      components: {
        Card,
        Steps,
        NeedHelp,
      }
    }
</script>
