<template>
    <div class="calltracking-setup-step1">
        <div class="row">
            <div class="col-lg-8">
                <card class="text-center">
                    <steps :step="3"></steps>

                    <h3>Tilføj telefonnumre</h3>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <p class="instructions">
                                For at bruge calltracking til at måle telefoniske henvendelser til din virksomhed,
                                skal dine opkald fremadrettet viderestilles fra et calltracking-telefonnummer (kendt som 70-nummer) til dit eksisterende telefonnummer. Du betaler ikke for viderestillingen og kunderne bemærker det ikke.
                            </p>

                            <p>
                                OBS: OP'N kan selv ændre telefonnumrene på din hjemmeside til calltracking-telefonnumre. Indtast derfor de(t) telefonnummer, der er synlig på din hjemmeside.
                            </p>
                        </div>
                    </div>

                    <br>

                    <phone-numbers :customer="customer" :customerId="customer.id" />

                    <hr>

                    <a @click="goBack()" class="btn btn-default btn-lg pull-left">Tilbage</a>
                    <router-link :to="{name: 'frontend.settings.integrations.calltracking.setup.verify'}" class="btn btn-primary btn-fill btn-lg pull-right">Næste</router-link>

                    <div class="clearfix"></div>
                </card>
            </div>

            <div class="col-lg-4">
                <need-help></need-help>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .calltracking-setup-step1 {
        .instructions {
            font-size: 16px;
        }

        .services {
            .service {
                cursor: pointer;
                padding: 15px 20px;
                text-align: center;
                border-radius: 20px;

                img {
                    max-width: 80%;
                    margin-bottom: 10px;
                }

                &:hover {
                    background-color: #f5f5f5;
                }
            }
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import PhoneNumbers from '@/app/integrations/components/calltracking/PhoneNumbers'
    import Steps from '@/app/integrations/components/calltracking/Steps'
    import NeedHelp from '@/app/integrations/components/NeedHelp'

    import {mapGetters} from 'vuex'

    export default {
      computed: {
        ...mapGetters('identity', {
          identity: 'getIdentity'
        }),
        ...mapGetters('customer', {
          customer: 'getCustomer'
        })
      },

      methods: {
        goBack() {
          history.back()
        }
      },

      components: {
        Card,
          PhoneNumbers,
        Steps,
        NeedHelp
      }
    }
</script>
