<template>
    <div class="dinero-setup-copy-html">
        <div class="row">
            <div class="col-lg-8">
                <card class="text-center">
                    <steps :step="2"></steps>

                    <h3>Få din API-nøgle af Dinero</h3>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <p class="instructions">
                                Log ind på Dinero og følg nedenstående <b>fire trin</b> for at få din API-nøgle.
                            </p>
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <h4>1. Gå til Indstillinger > API</h4>

                            <p class="instructions">
                                Tryk på <code>Indstillinger</code> i øverste højre hjørne. Vælg <code>API</code> i den menuen, der vises.
                            </p>
                        </div>
                    </div>

                    <img src="/images/settings/integrations/dinero/dinero-1.png" class="img-responsive">

                    <hr>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <h4>2. Opret en ny API-nøgle</h4>

                            <p class="instructions">
                                Tryk på knappen <code>Ny API-nøgle</code>.
                            </p>
                        </div>
                    </div>

                    <img src="/images/settings/integrations/dinero/dinero-2.png" class="img-responsive">

                    <br>

                    <hr>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <h4>3. Vælg et navn</h4>

                            <p class="instructions">
                                Vælg et navn til din API-nøgle. Vi anbefaler, at du navngiver din nøgle <code>SpotOn Live</code>. Tryk på knappen <code>Opret API-nøgle</code>.
                            </p>
                        </div>
                    </div>

                    <img src="/images/settings/integrations/dinero/dinero-3.png" class="img-responsive">

                    <br>

                    <hr>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <h4>4. Modtag din nye nøgle</h4>

                            <p class="instructions">
                                Dinero har nu generet din nye API-nøgle. Nu mangler du bare at indtaste API-nøglen i OP'N for at forbinde til Dinero.
                            </p>

                            <p class="instructions">
                                Tryk på <code>Næste</code> i bunden af denne side for at færdiggøre din opsætning.
                            </p>
                        </div>
                    </div>

                    <img src="/images/settings/integrations/dinero/dinero-4.png" class="img-responsive">

                    <br>

                    <hr>

                    <router-link :to="{name: 'frontend.settings.integrations.dinero.setup.startPage'}" class="btn btn-default btn-lg pull-left">Tilbage</router-link>
                    <router-link :to="{name: 'frontend.settings.integrations.dinero.setup.verify'}" class="btn btn-primary btn-fill btn-lg pull-right">Næste</router-link>

                    <div class="clearfix"></div>
                </card>
            </div>

            <div class="col-lg-4">
                <need-help></need-help>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .dinero-setup-copy-html {
        .instructions {
            font-size: 16px;
        }

        #guideModal {
            hr {
                margin-left: -15px;
                margin-right: -15px;
            }
        }

        .collapse-container {
            border-radius: 4px;
            border-top: 1px solid #dedede;
            border-right: 1px solid #dedede;
            border-left: 1px solid #dedede;

            .panel-group {
                margin: 0;
            }

            .panel-body {
                padding: 30px;
            }

            .panel-title > a {
                padding-left: 15px;
                font-size: 16px;
            }

            .panel-collapse > .panel-body {
                box-shadow: none;
            }
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import CodeSnippet from '@/app/integrations/components/calltracking/CodeSnippet'
    import Steps from '@/app/integrations/components/dinero/Steps'
    import NeedHelp from '@/app/integrations/components/NeedHelp'

    import {mapGetters} from 'vuex'

    export default {
      computed: {
        ...mapGetters('identity', {
          identity: 'getIdentity'
        }),
        ...mapGetters('customer', {
          customer: 'getCustomer'
        }),

        domain() {
          if (!this.customer) {
            return null;
          }

          return this.customer.domain;
        }
      },

      components: {
        Card,
        CodeSnippet,
        Steps,
        NeedHelp,
      }
    }
</script>
