<template>
    <div class="calltracking-setup-copy-html">
        <div class="row">
            <div class="col-lg-8">
                <card class="text-center">
                    <steps :step="2"></steps>

                    <h3>Kopier API-nøgle til Wordpress</h3>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <p class="instructions">
                                For at lave en sikker forbindelse mellem din hjemmeside og OP'N, skal der
                                tilføjes en API-nøgle til din hjemmeside.
                            </p>
                        </div>
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <h4>1. Kopier API-nøgle</h4>

                            <p class="instructions">
                                Start med at kopiere nedenstående API-nøgle.
                            </p>

                            <br>

                            <div class="well">
                                <strong>Din API-nøgle</strong>

                                <br>
                                <br>

                                <template v-if="token">
                                    <textarea rows="3" class="form-control" :value="token" readonly
                                              v-if="token"></textarea>
                                    <br>
                                    <a class="btn btn-primary btn-fill" v-clipboard="token" @click="clipHolderResponse">Kopier
                                        til udklipsholder</a>
                                </template>

                                <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true" v-else></i>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <h4>2. Indsæt API-nøgle i WordPress-plugin</h4>

                    <img src="/images/settings/integrations/wordpress/wordpress-api-key.png"
                         class="img-responsive"/>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <p class="instructions">
                                Gå derefter til dit Wordpress administrationspanel. Klik på <code>SpotOn Live</code> i
                                menuen til venstre.
                            </p>

                            <p class="instructions">
                                Indsæt derefter API-nøglen i feltet API-key. Tryk derefter <code>Gem ændringer</code>.
                            </p>

                            <p class="instructions">
                                Slut af med at aktivere opkaldstracking ved at trykke på knappen <code>Aktivér</code>.
                            </p>

                            <br>
                        </div>
                    </div>

                    <br>

                    <hr>

                    <a @click="goBack()" class="btn btn-default btn-lg pull-left">Tilbage</a>
                    <router-link :to="{name: 'frontend.settings.integrations.calltracking.setup.addPhoneNumbers'}"
                                 class="btn btn-primary btn-lg btn-fill pull-right">Næste
                    </router-link>

                    <div class="clearfix"></div>
                </card>
            </div>

            <div class="col-lg-4">
                <need-help></need-help>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .calltracking-setup-copy-html {
        .instructions {
            font-size: 16px;
        }

        textarea {
            text-align: center;
            background-color: white;
        }
    }
</style>

<script>
  import Card from '@/app/shared/components/Card'
  import CodeSnippet from '@/app/integrations/components/calltracking/CodeSnippet'
  import Steps from '@/app/integrations/components/calltracking/Steps'
  import NeedHelp from '@/app/integrations/components/NeedHelp'

  import * as TokenService from '@/services/customers/TokenService'

  import {mapGetters} from 'vuex'

  export default {
    data () {
      return {
        token: false
      }
    },

    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      })
    },

    mounted () {
      this.getApiToken()
    },

    methods: {
      goBack () {
        history.back()
      },

      getApiToken () {
        TokenService.getApiToken({}, (response) => {
          this.token = response.data.token
        })
      },

      clipHolderResponse () {
      }
    },

    components: {
      Card,
      CodeSnippet,
      Steps,
      NeedHelp
    }
  }
</script>
