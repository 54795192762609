import MixpanelService from '@/services/mixpanel/MixpanelService'

export default {
    methods: {
      connectEconomic() {
        let url = 'https://secure.e-conomic.com/secure/api1/requestaccess.aspx';

        const parameters = {
          appPublicToken: 'bnY3sn0ZYuy6CkUjuPe3SNsLmcXbBRIA4kjRvzhXGEk1',
          redirectUrl: this.callbackUrl('economic')
        };

        // Build url

        let query = "";

        for(let key in parameters) {
          var value = parameters[key];
          query += encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
        }

        if (query.length > 0){
          query = query.substring(0, query.length - 1); //chop off last "&"
          url = url + "?" + query;
        }

        MixpanelService.track('Integration - Intention', {'Integration type': 'Economic'})

        // Redirect

        location.href = url;
      },

      callbackUrl(service) {
        return document.location.origin + '/reporting/economy/callback/' + service
      }
    }
}
