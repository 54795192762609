<template>
    <div class="checkbox-container">
        <div class="box">
            <i class="check fa fa-check" v-if="checked"></i>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .checkbox-container {
        .box {
            line-height: 20px;
            border-radius: 4px;
            border: 2px solid #ccc;
            height: 24px;
            width: 24px;
        }

        .check {
            color: #00c69e;
            padding-left: 3px;
        }
    }
</style>

<script>
    export default {
        props: {
            checked: {
                type: Boolean,
                default: false
            }
        }
    }
</script>