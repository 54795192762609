<template>
    <div class="remove-integration">
        <a @click="removeIntegration()" class="text-muted" v-if="!isRemoving">{{$t('onboarding.remove', {source:
            headline})}}</a>
        <i class="fa fa-circle-o-notch fa-spin" v-else></i>
    </div>
</template>

<script>
  const IntegrationService = require('@/services/integrations/IntegrationService')

  import { mapActions, mapGetters } from 'vuex'

  export default {
    data () {
      return {
        isRemoving: false
      }
    },

    props: {
      name: {
        type: String,
        required: true
      },

      headline: {
        type: String,
        required: true
      }
    },

    computed: {
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),

      integrations () {
        if (!this.customer) {
          return
        }

        return this.customer.integrations
      }
    },

    methods: {
      ...mapActions('identity', {
        reloadIdentity: 'loadIdentity'
      }),

      async removeIntegration () {

        try {
          const response = await this.$swal({
            title: $t('onboarding.deleteAlert.areYourSure'),
            text: $t('onboarding.deleteAlert.description', { source: this.headline }),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: $t('onboarding.deleteAlert.confirmButton'),
            cancelButtonText: $t('onboarding.deleteAlert.cancelButton')
          })

          if (response) {
            this.isRemoving = true

            for (const integration of this.integrations) {
              if (!integration.service) {
                return null
              }

              if (integration.service.name === this.name) {
                const removed = await IntegrationService.remove('integrations', integration.id)

                if (removed) {
                  await this.reloadIdentity()
                }

                this.isRemoving = false
              }
            }
          }
        } catch (e) {
          this.isRemoving = false
          throw e
        }
      }
    }
  }
</script>
