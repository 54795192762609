<template>
    <div class="formtracking-setup-step1">
        <div class="row">
            <div class="col-lg-8">
                <card class="text-center">
                    <steps :step="4"></steps>

                    <h3>Emailtracking er opsat</h3>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <p class="instructions">
                                Tillykke din opsætning er færdig. Du kan nu følge udviklingen i antal henvendelser via
                                email.
                            </p>

                            <div>
                                <animated-check type="check"></animated-check>
                                <p class="success-message">Tillykke!</p>

                            </div>

                            <br>

                            <p class="instructions">
                                Se at emailtracking virker ved at gå til <a :href="customer.domain">din hjemmeside</a>,
                                udfyld din formular
                                og send en email til dig selv. Du vil efterfølgende kunne se denne e-mail under
                                <router-link :to="{name: 'frontend.inquiries'}">Mail og Opkald</router-link>
                                i OP'N.
                            </p>
                        </div>
                    </div>

                    <br>

                    <hr>

                    <a @click="goBack()" class="btn btn-default btn-lg pull-left">Tilbage</a>
                    <a @click="integrateFormService()" class="btn btn-lg btn-primary btn-fill pull-right">

                        <span v-if="!isPosting">Færdig</span>
                        <span v-else><i class="fa fa-circle-o-notch fa-spin"></i></span>
                    </a>

                    <div class="clearfix"></div>
                </card>
            </div>

            <div class="col-lg-4">
                <need-help></need-help>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .formtracking-setup-step1 {
        .instructions {
            font-size: 16px;
        }

        .loader {
            margin: 20px 0;
        }

        .testing-message {
            color: #ff8c00;
            font-size: 20px;
        }

        .success-message {
            color: #00c69e;
            font-size: 20px;
            margin-top: 10px;
        }

        .services {
            .service {
                cursor: pointer;
                padding: 15px 20px;
                text-align: center;
                border-radius: 20px;

                img {
                    max-width: 80%;
                    margin-bottom: 10px;
                }

                &:hover {
                    background-color: #f5f5f5;
                }
            }
        }
    }
</style>

<script>
  import Card from '@/app/shared/components/Card'
  import LargeLoader from '@/app/layout/components/LargeLoader'
  import PhoneNumbers from '@/app/integrations/components/calltracking/PhoneNumbers'
  import Steps from '@/app/integrations/components/formtracking/Steps'
  import AnimatedCheck from '@/app/layout/components/AnimatedCheck'
  import NeedHelp from '@/app/integrations/components/NeedHelp'

  import * as FormService from '@/services/inquiries/FormService'

  import { mapActions, mapGetters } from 'vuex'

  export default {
    data () {
      return {
        isPosting: false
      }
    },

    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      })
    },

    methods: {
      ...mapActions('identity', {
        reloadIdentity: 'loadIdentity'
      }),

      goBack () {
        history.back()
      },

      integrateFormService () {
        this.isPosting = true

        FormService.integrate({}, async () => {
          await this.reloadIdentity()
          this.isPosting = false
          this.$router.push({ name: 'frontend.settings.integrations' })
          MixpanelService.track('Integration - Create', { 'Integration type': 'Form tracking' })
        }, () => {
          this.isPosting = false
        })
      }
    },

    components: {
      PhoneNumbers,
      Steps,
      LargeLoader,
      AnimatedCheck,
      NeedHelp
    }
  }
</script>
