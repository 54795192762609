<template>
    <div class="calltracking-setup-step1">
        <div class="row">
            <div class="col-lg-8">
                <card class="text-center">
                    <steps :step="4"></steps>

                    <h3>Tester din opsætning</h3>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <p class="instructions">
                                OP'N tester om din opkaldstracking er opsat korrekt på din hjemmeside <code>{{customer.domain}}</code>
                            </p>

                            <div class="loader" v-if="!hasLoaded">
                                <large-loader></large-loader>
                                <p class="testing-message instructions">Tester...</p>
                            </div>


                            <template v-else>
                                <div v-if="hasFreespeeScript">
                                    <animated-check type="check"></animated-check>
                                    <p class="success-message">Tillykke!</p>

                                    <br>

                                    <p class="instructions">Opkaldstracking er sat korrekt op på din hjemmeside. Du kan nu følge udvikling i antal opkald, genlytte dine kundesamtaler og holde overblik over dine henvendelser.</p>

                                    <p class="instructions">
                                        Inden for en time vil du se dine telefonnumre blive skiftet ud på <a target="_blank" rel="noopener noreferrer" :href="customer.domain">din hjemmeside</a>.
                                        Se at opkaldstracking virker ved at lave et opkald til dig selv. Samtalen vil du efterfølgende kunne se under <router-link :to="{name: 'frontend.inquiries'}">Mail og Opkald</router-link> i OP'N.
                                    </p>
                                </div>

                                <template v-else>
                                    <br>

                                    <div class="alert alert-danger instructions">
                                        <strong>Desværre</strong> Opkaldstracking er ikke opsat korrekt. Gå til <router-link :to="{name: 'frontend.settings.integrations.calltracking.setup.chooseConfiguration'}">trin 1</router-link> i denne guide for at opsætte din opkaldstracking korrekt.
                                    </div>

                                    <br>
                                </template>
                            </template>
                        </div>
                    </div>

                    <br>

                    <hr>

                    <a @click="goBack()" class="btn btn-default btn-lg pull-left">Tilbage</a>
                    <router-link :to="{name: 'frontend.settings.integrations'}" class="btn btn-lg btn-primary btn-fill pull-right">Færdig</router-link>

                    <div class="clearfix"></div>
                </card>
            </div>

            <div class="col-lg-4">
                <need-help></need-help>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .calltracking-setup-step1 {
        .instructions {
            font-size: 16px;
        }

        .loader {
            margin: 20px 0;
        }

        .testing-message {
            color: #ff8c00;
            font-size: 20px;
        }

        .success-message {
            color: #00c69e;
            font-size: 20px;
            margin-top: 10px;
        }

        .services {
            .service {
                cursor: pointer;
                padding: 15px 20px;
                text-align: center;
                border-radius: 20px;

                img {
                    max-width: 80%;
                    margin-bottom: 10px;
                }

                &:hover {
                    background-color: #f5f5f5;
                }
            }
        }
    }
</style>

<script>
    const FreespeeService = require('@/services/calltracking/FreespeeService')

    import Card from '@/app/shared/components/Card'
    import LargeLoader from '@/app/layout/components/LargeLoader'
    import PhoneNumbers from '@/app/integrations/components/calltracking/PhoneNumbers'
    import Steps from '@/app/integrations/components/calltracking/Steps'
    import AnimatedCheck from '@/app/layout/components/AnimatedCheck'
    import NeedHelp from '@/app/integrations/components/NeedHelp'

    import MixpanelService from '@/services/mixpanel/MixpanelService'

    import {mapGetters} from 'vuex'

    export default {
        data() {
            return {
                hasFreespeeScript: false,
                hasLoaded: false,
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),
          ...mapGetters('customer', {
            customer: 'getCustomer'
          })
        },

        mounted() {
            this.checkForScript();
        },

        methods: {
            goBack() {
                history.back()
            },

            checkForScript() {
                FreespeeService.hasFreespeeScript(this.customer.id, ({ data }) => {
                    if (data.hasFreespeeScript) {
                        this.hasFreespeeScript = true
                        MixpanelService.track('Integration - Create', {'Integration type': 'Call tracking'})
                    }

                    this.hasLoaded = true
                }, () => {
                    this.hasLoaded = true
                })
            }
        },

        components: {
            Card,
            PhoneNumbers,
            Steps,
            LargeLoader,
            AnimatedCheck,
            NeedHelp,
        }
    }
</script>
