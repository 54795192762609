<template>
    <div class="dinero-input-api-key">
        <div class="row">
            <div class="col-lg-8">
                <card class="text-center">
                    <steps :step="3"></steps>

                    <h3>Indtast API-nøgle</h3>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <p class="instructions">
                                Indtast din nye API-nøgle i feltet nedenfor.
                            </p>

                            <br>
                            <br>

                            <div class="alert alert-danger" v-if="hasFailed">
                                <strong>Hov!</strong>
                                Du har udfyldt en ugyldig nøgle. Tjek at nøglen er indtastet korrekt og prøv igen.
                            </div>

                            <div class="well text-center">
                                <strong class="api-key-label">API-nøgle</strong>

                                <input v-model="key" class="form-control" placeholder="F.eks. 5e89f005a8a34c37abbb32b20c4aebbf" />
                            </div>

                            <a @click="connect()" class="btn btn-primary btn-fill btn-lg submit-botton">
                                <span v-if="!isSubmitting">Opret forbindelse til Dinero</span>
                                <i class="fa fa-circle-o-notch fa-spin" v-else></i>
                            </a>

                            <br>
                            <br>
                        </div>
                    </div>

                    <br>

                    <hr>

                    <a @click="goBack()" class="btn btn-default btn-lg pull-left">Tilbage</a>

                    <div class="clearfix"></div>
                </card>
            </div>

            <div class="col-lg-4">
                <need-help></need-help>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .dinero-input-api-key {
        .instructions {
            font-size: 16px;
        }

        .api-key-label {
            margin-bottom: 5px;
            display: block;
        }

        .submit-botton {
            i {
                margin-left: 30px;
                margin-right: 30px;
            }
        }

        .alert-danger {
            margin-top: -30px;
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import Steps from '@/app/integrations/components/dinero/Steps'
    import NeedHelp from '@/app/integrations/components/NeedHelp'

    import {mapGetters, mapActions} from 'vuex'

    const IntegrationService = require('@/services/orders/IntegrationService');
    import MixpanelService from '@/services/mixpanel/MixpanelService'

    export default {
      data() {
        return {
          key: null,
          hasLoaded: false,
          hasFailed: false,
          isSubmitting: false,
        }
      },

      computed: {
        ...mapGetters('identity', {
          identity: 'getIdentity'
        }),
        ...mapGetters('customer', {
          customer: 'getCustomer'
        })
      },


      methods: {
        ...mapActions('identity', {
          reloadIdentity: 'loadIdentity'
        }),

        goBack() {
          history.back()
        },

        connect() {
          this.isSubmitting = true;

          IntegrationService.integrate('dinero', {data: this.key}, async () => {
            await this.reloadIdentity();

            this.hasLoaded = true;
            this.isSubmitting = false;
            this.hasFailed = false;

            MixpanelService.track('Integration - Create', {'Integration type': 'Dinero'})

            this.$router.push({name: 'frontend.settings.integrations.dinero.setup.success'})
          }, (error) => {
            this.hasFailed = true;
            this.hasLoaded = true;
            this.isSubmitting = false;
          })
        }
      },

      components: {
        Card,
        Steps,
        NeedHelp,
      }
    }
</script>
