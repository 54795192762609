<template>
    <div class="wordpress-steps">
        <div class="steps text-left">
            <div class="step" :class="{'active' : step == 1}">
                <span class="number">1</span>
                Vælg opsætning
            </div>

            <div class="step" :class="{'active' : step == 2}">
                <span class="number">2</span>
                Installér
            </div>

            <div class="step" :class="{'active' : step == 3}">
                <span class="number">3</span>
                Indtast telefonnumre
            </div>

            <div class="step last" :class="{'active' : step == 4}">
                <span class="number">4</span>
                Verificer
            </div>

            <div class="clearfix"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .wordpress-steps {
        .steps {
            margin: -30px -30px 45px -30px;
            padding: 1px 0 10px;

            .step {
                border-bottom: 1px solid #dedede;
                float: left;
                width: 25%;
                line-height: 65px;
                color: #999;
                padding-left: 15px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .number {
                    border: 1px solid #dedede;
                    border-radius: 999px;
                    width: 37px;
                    line-height: 35px;
                    display: inline-block;
                    margin-right: 8px;
                    text-align: center;
                }

                &.active {
                    color: initial;
                    border-bottom: 1px solid #4096ee;
                }
            }
        }
    }
</style>

<script>
    export default {
        props: {
          step: {
            type: Number,
            default: 1
          }
        },

        mounted() {
            this.scrollToTop();
        },

        methods: {
            scrollToTop() {
                this.$el.scrollIntoView(true)
            }
        }
    }
</script>