const BaseService = require('@/services/BaseService')

const namespace = 'matomo'

const connect = (options, cb, errorCb) => {
  BaseService.post(`${namespace}/connect`, options, cb, errorCb)
}

const integrate = (options, cb, errorCb) => {
  BaseService.post(`${namespace}/integrate`, options, cb, errorCb)
}

export default {
	connect,
	integrate
}
