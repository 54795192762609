<template>
    <div class="trustpilot-setup">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-8">
                    <card class="text-center">
                        <div class="row">
                            <div class="col-lg-8 col-lg-push-2">
                                <!-- Integrate -->
                                <template v-if="!hasBeenIntegrated">
                                    <br>

                                    <!-- Headline -->
                                    <h3>{{$t('onboarding.connectTo', {source: 'Trustpilot'})}}</h3>

                                    <br>

                                    <!-- Description -->
                                    <p class="text-muted intro">
                                        Vi kunne ikke finde din virksomhed på Trustpilot ud fra din hjemmeside

                                        <a v-if="domain" :href="domain" target="_blank" rel="noopener noreferrer">{{domain}}</a>.
                                        Find din virksomhed ved at søge herunder.
                                    </p>

                                    <br>

                                    <!-- Search input -->
                                    <div class="input-container">
                                        <input v-model="query" class="form-control" placeholder="Søg efter din virksomhed">

                                        <i v-if="isSearching" class="fa fa-circle-o-notch fa-spin"></i>
                                    </div>

                                    <!-- Logo -->
                                    <div class="text-right trustpilot-logo-container">
                                        <img src="/images/onboarding/trustpilot.png" class="trustpilot-logo">
                                        <small>Trustpilot</small>
                                    </div>

                                    <br>
                                    <br>

                                    <!-- Results -->
                                    <transition name="slide-fade">
                                        <div v-if="showResults" class="results">
                                            <div class="results-header">
                                                <h4>Vælg din virksomhed</h4>
                                            </div>

                                            <!-- Result -->
                                            <div class="results-row text-left" v-if="results" v-for="result in results" @click="select(result.id)">
                                                <checkbox :checked="isSelected(result.id)" class="pull-left results-checkbox"></checkbox>

                                                <span class="pull-left results-name">{{result.displayName}}</span>

                                                <a v-if="result.name" :href="formatReviewUrl(result.name.identifying)" class="pull-right">
                                                    {{result.name.identifying}}
                                                </a>

                                                <div class="clearfix"></div>
                                            </div>

                                            <!-- No results -->
                                            <div class="results-row" v-if="results.length === 0">
                                                {{$t('onboarding.noResults')}}
                                            </div>

                                            <br>

                                            <div class="btn btn-brand btn-lg btn-fill" @click="connectToTrustpilot()" v-if="selectedResult" :class="{disabled: !trustpilotIntegrationId}">
                                                <span v-if="!isPosting">
                                                    <template v-if="!trustpilotIntegrationId">
                                                        <span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
                                                    </template>

                                                    {{$t('onboarding.connectTo', {source: 'Trustpilot'})}}
                                                </span>

                                                <i class="fa fa-circle-o-notch fa-spin" v-else></i>
                                            </div>

                                            <br>
                                        </div>
                                    </transition>

                                    <br>
                                </template>

                                <!-- Fetching reviews -->
                                <template v-else>
                                    <animated-check></animated-check>

                                    <h3>{{$t('onboarding.connectedWithSource', {source: 'Trustpilot'})}}</h3>

                                    <br>

                                    <p class="intro">
                                        Dine Trustpilot-anmeldelser bliver over de næste 2 minutter hentet ind i SpotOn
                                        Live.
                                        Så kig snart forbi
                                        <router-link :to="{name: 'frontend.reviews'}">Omdømme</router-link>
                                        -siden.
                                    </p>

                                    <br>

                                    <router-link :to="{name: 'frontend.reviews'}" class="btn btn-primary btn-fill btn-lg">
                                        Gå til Omdømme
                                    </router-link>

                                    <br>
                                    <br>
                                    <br>
                                </template>

                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </card>
                </div>

                <!-- Help -->
                <div class="col-lg-4">
                    <need-help></need-help>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .trustpilot-setup {
        h3 {
            margin-top: 30px;
        }

        .intro {
            font-size: 16px;
        }

        .input-container {
            position: relative;

            i {
                position: absolute;
                right: 15px;
                top: 13.5px;
            }
        }

        .trustpilot-logo-container {
            margin-top: 10px;

            .trustpilot-logo {
                width: 16px;
                margin-right: 2px;
            }
        }

        .results-header {
            background-color: #fbfbfb;
            border-bottom: 1px solid #eee;

            h4 {
                margin: 0;
                font-size: 16px;
                line-height: 50px;
            }
        }

        .results-name {
            display: inline-block;
            width: 30%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .results-checkbox {
            margin-top: 13px;
            margin-right: 15px;
        }

        .results-row {
            border-bottom: 1px solid #eee;
            line-height: 50px;
            font-size: 16px;

            &:hover {
                background-color: #fbfbfb;
                cursor: pointer;
            }
        }

        .success-text {
            color: #00c69e;
            font-size: 16px;
        }
    }
</style>

<script>
  import Card from '@/app/shared/components/Card'
  import Checkbox from '@/app/layout/components/Checkbox'
  import NeedHelp from '@/app/integrations/components/NeedHelp'
  import AnimatedCheck from '@/app/layout/components/AnimatedCheck'

  import * as ReviewService from '@/services/reviews/ReviewService'
  import * as TrustpilotService from '@/services/reviews/TrustpilotService'
  import * as ServiceService from '@/services/integrations/ServiceService'
  import MixpanelService from '@/services/mixpanel/MixpanelService'

  import { debounce } from 'lodash'

  import { mapActions, mapGetters } from 'vuex'

  export default {
    data () {
      return {
        query: null,

        results: [],
        showResults: false,
        selectedResult: null,

        isSearching: false,
        isPosting: false,
        hasBeenIntegrated: false,

        trustpilotIntegrationId: null
      }
    },

    watch: {
      query () {
        this.isSearching = true

        if (this.query.length === 0) {
          this.results = []
          this.showResults = false

          return
        }

        this.search()
      }
    },

    mounted () {
      this.fetchTrustpilotIntegrationId()
    },

    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),

      domain () {
        if (!this.customer) {
          return null
        }

        return 'http://' + this.customer.domain
      }
    },

    methods: {
      ...mapActions('identity', {
        reloadIdentity: 'loadIdentity'
      }),

      search: debounce(function () {
        this.selectedResult = null

        TrustpilotService.search(this.query, {}, (response) => {
          const data = response.data

          this.results = data.businessUnits
          this.isSearching = false
          this.showResults = true
        }, () => {
          this.isSearching = false
        })
      }, 500),

      select (id) {
        this.selectedResult = id
      },

      isSelected (id) {
        return (id === this.selectedResult)
      },

      formatReviewUrl (url) {
        return 'https://dk.trustpilot.com/review/' + url
      },

      fetchTrustpilotIntegrationId () {
        ServiceService.findAll({
          filter_groups: [
            {
              filters: [
                {
                  key: 'name',
                  value: 'Trustpilot review',
                  operator: 'eq'
                }
              ]
            }
          ]
        }, (response) => {
          const data = response.data
          const rows = data.rows

          const integrations = rows.filter((integration) => {
            return integration.name === 'Trustpilot review'
          })

          this.trustpilotIntegrationId = integrations[0].id
        })
      },

      connectToTrustpilot () {
        if (!this.selectedResult) {
          return
        }

        const result = this.results.find(result => {
          return result.id === this.selectedResult
        })

        if (!result) {
          return
        }

        this.isPosting = true

        const options = {
          id: result.id,
          account: result.name.identifying,
		  customer: this.customer.id,
          service: this.trustpilotIntegrationId
        }

        ReviewService.integrate(options, async () => {
          this.reloadIdentity()

          this.hasBeenIntegrated = true

          MixpanelService.track('Integration - Create', { 'Integration type': 'Trustpilot' })
        })
      }
    },

    components: {
      Card,
      Checkbox,
      NeedHelp,
      AnimatedCheck
    }
  }
</script>
