<template>
    <div class="need-help">
        <card :headline="$t('integrations.needHelp.headline')"
			  :description="$t('integrations.needHelp.description')"
			  icon="pe-7s-help1">
            <hr>

            <div class="row">
                <div class="col-md-8 col-md-push-2 text-center">
                    <div class="img-container center-block">
                        <img src="/images/employees/salma_elrefai.jpg" class="img-responsive">
                    </div>

                    <br>

                    <p class="text-muted">{{ $t('integrations.needHelp.text') }}</p>

					<div class="contact">
						<div>
							<i class="fa fa-phone fa-fw" />
							<a :href="`tel:${$t('integrations.needHelp.telephone')}`">{{ $t('integrations.needHelp.telephone') }}</a>
						</div>

						<div>
							<i class="fa fa-envelope fa-fw" />
							<a :href="`mail-to:${$t('integrations.needHelp.email')}`">{{ $t('integrations.needHelp.email') }}</a>
						</div>
					</div>
                </div>
            </div>
        </card>
    </div>
</template>

<style lang="scss" scoped>
    .need-help {
        .img-container {
            width: 150px;
            height: 150px;
            overflow: hidden;
            border-radius: 999px;
            border: 1px solid #dedede;

        }

		.contact {
			display: flex;
			flex-direction: column;
			gap: 10px;
			margin-top: 15px;

			> div {
				i {
					margin-right: 10px;
				}

				a {
					color: #000;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'

    export default {
      components: {
        Card
      }
    }
</script>
