<template>
    <div class="calltracking-setup-choose-configuration">
        <div class="row">
            <div class="col-lg-8">
                <card class="text-center">
                    <steps :step="1"></steps>

                    <h3>Opsætning af ‘Opkaldstracking’</h3>

                    <br>

                    <div class="row">
                        <div class="col-md-10 col-md-push-1">
                            <p class="instructions">
                                For at opsætte opkaldstracking og måle dine henvendelser har OP'N brug for
                                at få adgang til din hjemmeside. Du kan installere opkaldstracking plugin i WordPress, hvilket er nemt og hurtigt. Hvis du ikke bruger WordPress, kan du i stedet indsætte en HTML-kode på din hjemmeside. Vælg en af de to guides for at komme i gang.
                            </p>
                        </div>
                    </div>

                    <br />


                    <div class="text-center">
                        <span class="lead">Vælg din guide</span>
                    </div>

                    <br />

                    <div class="services">
                        <!-- WordPress -->
                        <div class="col-md-4 col-md-push-2 col-xs-6">
                            <div class="service" :class="{'chosen': chosen == 'wordpress'}" @click="chosen = 'wordpress'">
                                <img src="/images/calltracking/services/wordpress.svg"><br />
                                <span>WordPress</span>

                                <i class="fa fa-check"></i>
                            </div>
                        </div>

                        <!-- HTML -->
                        <div class="col-md-4 col-md-push-2 col-xs-6">
                            <div class="service" :class="{'chosen': chosen == 'html'}" @click="chosen = 'html'">
                                <img src="/images/calltracking/services/html.svg"><br />
                                <span>HTML</span>

                                <i class="fa fa-check"></i>
                            </div>
                        </div>
                    </div>

                    <div class="clearfix"></div>

                    <br>

                    <template v-if="chosen">
                        <hr>

                        <button @click="next" v-if="chosen" class="btn btn-primary btn-lg btn-fill pull-right">
                            <template v-if="!hasLoaded">
                                <span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
                            </template>
                            <template v-else>
                                Næste
                            </template>
                        </button>

                        <div class="clearfix"></div>
                    </template>
                </card>
            </div>

            <div class="col-lg-4">
                <need-help></need-help>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .calltracking-setup-choose-configuration {
        .instructions {
            font-size: 16px;
        }

        .services {
            .service {
                cursor: pointer;
                padding: 30px;
                text-align: center;
                border-radius: 8px;
                border: 1px solid #dedede;
                position: relative;
                transition: background-color 0.4s;
                margin-top: 5px;

                &.chosen {
                    background-color: rgba(0, 218, 186, 0.04);
                    border-color: #00c49f;

                    i {
                        display: block;
                        position: absolute;
                        right: -11px;
                        bottom: -11px;
                        background: #00c49f;
                        color: white;
                        border-radius: 999px;
                        width: 25px;
                        line-height: 25px;
                        font-size: 16px;
                    }
                }

                i {
                    display: none;
                }

                span {
                    color: initial;
                }

                img {
                    max-width: 75%;
                    margin-bottom: 10px;
                }

                &:hover:not(.chosen) {
                    background-color: #f5f5f5;
                    cursor: pointer;
                }
            }
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import Steps from '@/app/integrations/components/calltracking/Steps'
    import NeedHelp from '@/app/integrations/components/NeedHelp'
    import * as FreespeeService from '@/services/calltracking/FreespeeService'

    import {mapGetters} from 'vuex'

    import organization from '@/mixins/customer/organization'

    export default {
        mixins: [organization],

        computed: {
			...mapGetters('identity', {
				identity: 'getIdentity'
			}),
			...mapGetters('customer', {
				customer: 'getCustomer'
			})
		},

        data() {
            return {
                chosen: '',
                hasLoaded: true,
            }
        },

        beforeMount() {
            if (this.isFreemium) {
                return
            }

            this.$router.push({name: 'frontend.settings.integrations.calltracking.salesPage'})
        },

        methods: {
            next() {
                if (!this.hasLoaded) {
                    return
                }

                this.hasLoaded = false

                const identity = this.identity
                const customer = this.customer

                if (!customer) {
                    return
                }

                FreespeeService.createAccount(customer.id, () => {
                    switch (this.chosen) {
                        case 'wordpress':
                            this.$router.push({
                                name: 'frontend.settings.integrations.calltracking.setup.wordpressInstallPlugin'
                            })
                            break;

                        default:
                        case 'html':
                            this.$router.push({
                                name: 'frontend.settings.integrations.calltracking.setup.copyHtml'
                            })
                            break;
                    }
                })
            }
        },

        components: {
            Card,
            Steps,
            NeedHelp,
        }
    }
</script>
