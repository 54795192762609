const BaseService = require('@/services/BaseService')

const namespace = 'wp-plugin'

const get = (options, cb, errorCb) => {
    BaseService.get(`${namespace}/key`, options, cb, errorCb)
}
const generate = (options, cb, errorCb) => {
    BaseService.post(`${namespace}/generate`, options, cb, errorCb)
}

export default {
    get,
    generate,
}
